import React, { useState } from "react";
import Chats from "./chats/Chat";
import { analyzeNextSteps } from "./HelperFunctions/analyzeNextSteps";
import "./Chatbot.scss";
 import {getClickedVisual,getClickedVisualObject} from './TestApp'
interface ResponseBotObject {
  key:string;
  purpose: string;
  message: string;
  options?: string[];
  sender: string;
}
interface ResponseUserObject {
  key:string;
  message: string;
}

const Chatbot: React.FC = () => {
  const [userResponse, setUserResponse] = useState<ResponseUserObject>({
    key:"",
    message: "",
  });
  const [step, setStep] = useState<number>(0);
  const [botResponse, setBotResponse] = useState<ResponseBotObject>({
    key:"",
    purpose: "",
    message: "",
    sender: "bot"
  });
  const [sendUserResponse, setSendUserResponse] = useState<ResponseUserObject>({
    key:"",
    message: "",
  });

  // setting next step when there's response and option click
  const setNextStep = (response: string) => {
    (async () => {
      try {
        
        setStep(prevState => prevState + 1);
        setSendUserResponse( {key:crypto.randomUUID(),message:response});
        var clickedVisual = await getClickedVisualObject();
        let res = await analyzeNextSteps( getClickedVisual(),clickedVisual, response);
        if(res)
        {
       setBotResponse({ key:res.key,purpose:res.purpose,message:res.message, sender: "bot" });
       setUserResponse({key:crypto.randomUUID(),message:""});
        }
      } catch (error) {
        // Handle any errors that occurred during the asynchronous operation
        console.error(error);
      }
    })();  
  };

  // event handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setUserResponse({key:crypto.randomUUID(),message:e.target.value});
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setUserResponse({key:crypto.randomUUID(),message:""});
    setNextStep(userResponse.message);
  };

  return (
    <div className="chat-container">
      <Chats
        userResponse={userResponse}
        botResponse={botResponse}
        sendUserResponse={sendUserResponse}
      />
      <form onSubmit={e => handleSubmit(e)} className="form-container">
        <input
           onChange={e => handleInputChange(e)}
          value={userResponse.message}
        >

        </input>
        <button>
          <i className="far fa-paper-plane">Send</i>
        </button>
      </form>
    </div>
  );
};

export default Chatbot;
