import * as Msal from "msal";
import { ENV_CONFIG } from "../config/env.config";

export default class SpaAuthService {
  private static clientId: string = ENV_CONFIG.CLIENT_ID;
  private static authority: string = ENV_CONFIG.AUTH_URL;

  private static requestScopesPowerBi = {
    scopes: [
      ENV_CONFIG.POWER_BI_SCOPE
    ],
  };
  
  
  public static userIsAuthenticated: boolean = false;
  public static userDisplayName: string = "";
  public static userName: string = "";
  public static accessToken: string;
  public static uiUpdateCallback: any;

  private static msalConfig: Msal.Configuration = {
    auth: {
      clientId: SpaAuthService.clientId,
      authority: SpaAuthService.authority,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };

  private static userAgent: Msal.UserAgentApplication =
    new Msal.UserAgentApplication(SpaAuthService.msalConfig);

  static init = () => {
    let userAccount = SpaAuthService.userAgent.getAccount();

    if (userAccount) {
      SpaAuthService.userName = userAccount.userName;
      SpaAuthService.userDisplayName = userAccount.name;
      SpaAuthService.userIsAuthenticated = true;
      SpaAuthService.acquireAccessoken();
      SpaAuthService.acquireAccessokenForOpenAI();
    }

    SpaAuthService.userAgent.handleRedirectCallback(
      (error: Msal.AuthError, response?: Msal.AuthResponse) => {
        if (error) {
          console.log("Error during auth callback...");
        } else {
          if (response && response.tokenType === "access_token") {
            SpaAuthService.accessToken = response.accessToken;
            SpaAuthService.userDisplayName = response.account.name;
            SpaAuthService.userName = response.account.userName;
            SpaAuthService.userIsAuthenticated = true;
            if (SpaAuthService.uiUpdateCallback != undefined) {
              SpaAuthService.uiUpdateCallback();
            }
          } else {
          }
        }
      }
    );
  if (!userAccount) {
            SpaAuthService.userAgent.loginRedirect();
        }
  };

  static login = () => {
    SpaAuthService.userAgent
      .loginPopup(SpaAuthService.requestScopesPowerBi)
      .then((loginResponse: any) => {
        var account = SpaAuthService.userAgent.getAccount();
        SpaAuthService.userName = loginResponse.account.userName;
        SpaAuthService.userDisplayName = loginResponse.account.name;
        SpaAuthService.userIsAuthenticated = true;
        SpaAuthService.uiUpdateCallback();
        SpaAuthService.acquireAccessoken();
        SpaAuthService.acquireAccessokenForOpenAI();
      })
      .catch(function (error: any) {
        console.log("log in error...");
        console.log(error);
      });

    if (SpaAuthService.uiUpdateCallback) {
      SpaAuthService.uiUpdateCallback();
    }
  };

  static logout = () => {
    SpaAuthService.userIsAuthenticated = false;
    SpaAuthService.userAgent.logout();
    localStorage.clear();
  };

  private static acquireAccessoken() {
    SpaAuthService.userAgent
      .acquireTokenSilent(SpaAuthService.requestScopesPowerBi)
      .then(function (tokenResponse) {
        SpaAuthService.accessToken = tokenResponse.accessToken;
        SpaAuthService.uiUpdateCallback();
      })
      .catch(function (error) {
        console.log(error);
        if (SpaAuthService.requiresInteraction(error.errorCode)) {
          SpaAuthService.userAgent
            .acquireTokenPopup(SpaAuthService.requestScopesPowerBi)
            .then((tokenResponse) => {
            })
            .catch(function (error) {
              console.log("Error all the way down the line");
              console.log(error);
            });
        }
      });
  }

  private static acquireAccessokenForOpenAI() {
    SpaAuthService.userAgent
      .acquireTokenSilent({
        scopes: [
          ENV_CONFIG.OPENAI_SCOPE
        ],
      })
      .then(function (tokenResponse) {
        localStorage.setItem('openAIToken', tokenResponse.accessToken)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  private static requiresInteraction(errorCode: any) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === "consent_required" ||
      errorCode === "interaction_required" ||
      errorCode === "login_required"
    );
  }
}
