import React, { useState, useEffect, useRef } from "react";
//import { generateUUID } from "util";
import "./Chat.scss";

interface Props {
  userResponse:  {
    key: string;
    message: string;
  };
  botResponse: {
    key: string;
    purpose: string;
    message: string;
    options?: string[];
    sender: string;
  };
  sendUserResponse: {
    key: string;
    message: string;
  };
  //optionClick: (ev: React.MouseEvent<HTMLElement>) => void;
}

interface MessagesInfo {
  key:string;
  purpose?: string;
  message: string;
  options?: string[];
  sender: string;
}

const Chats: React.FC<Props> = props => {
  const [messages, setMessages] = useState<MessagesInfo[]>([]);
  const dummyRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  // stacking up messages
  useEffect(() => {
    if (messages.length === 0) {
      setMessages([
        {
          key:crypto.randomUUID(),
          purpose: "introduction",
          message:
            "Hi there, I am your Talent Bot. If you have a query on a particular visualization, please click on the same. Else click on clear visualization to consider entire dataset.",
          sender: "bot"
        }
      ]);
    } else {
      let tempArray = [...messages];
     if (!tempArray.some(item => item.key === props.sendUserResponse.key)) 
        tempArray.push({...props.sendUserResponse, sender: "user" });
      setMessages(tempArray);

      setTimeout(() => {
        let temp2 = [...tempArray];
        if (!temp2.some(item => item.key === props.botResponse.key) && props.botResponse.message.length > 0) 
        temp2.push(props.botResponse);
        setMessages(temp2);
      }, 100);
    }
  }, [props.sendUserResponse, props.botResponse]);

  // enable autoscroll after each message
  useEffect(() => {
    if (dummyRef && dummyRef.current && bodyRef && bodyRef.current) {
      bodyRef.current.scrollTo({
        top: dummyRef.current.offsetTop,
        behavior: "smooth"
      });
    }
  }, [messages]);

  return (
    <div className="message-container" ref={bodyRef}>
      {messages.map(chat => (
        <div key={chat.key}>
          <div className={`message ${chat.sender}`}>
            <p>{chat.message}</p>
          </div>
          {chat.options ? (
            <div className="options">
              <div>
                <i className="far fa-hand-pointer"></i>
              </div>
            </div>
          ) : null}
          <div ref={dummyRef} className="dummy-div"></div>
        </div>
      ))}
    </div>
  );
};

export default Chats;
