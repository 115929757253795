import { ENV_CONFIG } from "../../config/env.config";
import SpaAuthService from "../../services/SpaAuthService";

const apiUrl: string = ENV_CONFIG.OPENAI_URL;

export async function analyzeNextSteps  (visualName: string,currentVisual: { [key: string]: any }, userResponse:string) 
 {
  var currentVisualText = visualName != undefined && visualName.length > 0 ? 
                           "The selected visual is " + visualName+ ". ":
                        "Entire dataset considered.";
  if(currentVisual == undefined || currentVisual.length == 0)
  {
    currentVisual = {}
  }

    var result = ''
      const resultData = await postData(apiUrl,{"json_data": currentVisual ?? {}, "text":userResponse})
        result = resultData;
        return {
          key: crypto.randomUUID(),
          purpose: "specify field" ,
          message: currentVisualText + result,
        }
};

async function postData(url: string, body: any) {
  try {
    const token = localStorage.getItem('openAIToken');
    if(token == null)
    SpaAuthService.login();
    console.log('token is',token)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return "Sorry,unable to process this request";
  }
}


