export const ENV_CONFIG = {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
    AUTH_URL :process.env.REACT_APP_AUTH || '',
    REPORT_URL : process.env.REACT_APP_REPORT_URL || '',
    POWER_BI_SCOPE :process.env.REACT_APP_SCOPE || '',
    OPENAI_CLIENT_ID: process.env.REACT_APP_OPENAI_CLIENT_ID || '',
    OPENAI_CLIENT_SECRET: process.env.REACT_APP_OPENAI_CLIENT_SECRET || '',
    OPENAI_URL: process.env.REACT_APP_OPENAI_URL || '',
    OPENAI_SCOPE : process.env.REACT_APP_OPENAI_SCOPE || '',
	 REPORT_EMBED_URL: process.env.REACT_APP_REPORT_EMBED_URL || '',
	};