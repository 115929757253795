import React, { useEffect } from 'react';

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="popup">
          <p>Cleared selection!</p>
        </div>
      )}
    </>
  );
};

export default Popup;
