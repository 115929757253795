import React, { useEffect, useState } from "react";
import { models, Report, Embed, service, Page, Visual, VisualDescriptor } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "react-datepicker/dist/react-datepicker.css";
import "powerbi-report-authoring";
import "./DemoApp.css";
import SpaAuthService from "../services/SpaAuthService";
import Chatbot from "./Chatbot";
import Popup from "./ClearPopUp"
import { ENV_CONFIG } from "../config/env.config";

let visualName:string = '';
export let currentVisual :VisualDescriptor ;
 export function getClickedVisual(): string {
   return visualName;
}
export async function getClickedVisualObject(): Promise<{ [key: string]: any }> {
  if(currentVisual != undefined && visualName.length > 0)
  {
    
    var exportedData = await currentVisual.exportData(models.ExportDataType.Summarized)
    return  convertStringToDictionary(exportedData.data,'\n', ',')
 
  }
  return {};
}

const buttonStyle: React.CSSProperties = {
  width: '95px',
  float:"right",
  fontSize: 'small'
};

const clearButtonStyle: React.CSSProperties = {
  width: '123px',
  float:"right",
  fontSize: 'small'
};


// Root Component to demonstrate usage of wrapper component
function TestApp(): JSX.Element {
  const [report, setReport] = useState<Report>();
  const [token, setToken] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    SpaAuthService.accessToken && setToken(SpaAuthService.accessToken);
    
  }, [SpaAuthService.accessToken]);

  // Report config useState hook
  // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
  let sampleReportConfig = {
    type: "report",
    embedUrl:ENV_CONFIG.REPORT_EMBED_URL,
    accessToken: token,
    tokenType: models.TokenType.Aad,
    settings: undefined,
  };
   
  if (!report) {
  }
  else{
    report.on('loaded', () => {
      report.on('visualClicked', handleVisualClicked);
      report.on('pageChanged', pageChanged);
    });
  }
  
   const clearVisualization = () => {
    visualName = "";
    setIsOpen(true);
   }

   const closePopup = () => {
    setIsOpen(false);
  };

   const handleVisualClicked = async (event: any) => {
    
    if (!report) {
      return;
    }
    const clickedVisual = event.detail.visual;
     const pages = await report.getPages();

    // Get the active page
    const activePage = pages.filter(function (page) {
      return page.isActive;
    })[0];

    const visuals = await activePage.getVisuals();
    let visual = visuals.filter(function (visual) {
      return visual.name ===clickedVisual.name;
    })[0];
   
     visualName =  visual.title;
    currentVisual = visual;
  };

  const pageChanged = async (event: any) => {
    visualName =  "";
  };

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
      },
    ],
    [
      "rendered",
      function () {
      },
    ],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  
  const ParentComponent: React.FC = () => {
    const [showChatbot, setShowChatbot] = useState(false);
    const handleChatbotToggle = () => {
      setShowChatbot(!showChatbot);
    };
  
    return (
      <div>
        <button style={buttonStyle} onClick={handleChatbotToggle}><span>&#x1F5E8;&nbsp;</span>Ask me</button>
        <button  style= {clearButtonStyle} onClick={clearVisualization}><span></span>Clear Visualization</button>   
        {showChatbot && <Chatbot />} 
        <Popup isOpen={isOpen} onClose={closePopup} />   
      </div>
    );
  };


 

  const [displayMessage, setMessage] = useState(
    ``
  );

  const header = (
    <div className="header">
      <div className="title">Power BI</div>
    </div>
  );

  return (
    <div>
      {header}
      <br></br>
      {token && (
        <>
          <PowerBIEmbed
            embedConfig={sampleReportConfig}
            eventHandlers={eventHandlersMap}
            cssClassName={"report-style-class"}
            getEmbeddedComponent={(embedObject: Embed) => {
              setReport(embedObject as Visual);
              if (!report) {
                return;
              }
              report.on('visualClicked', handleVisualClicked);
              report.on('pageChanged', pageChanged);
            }}
          />                 
        <ParentComponent />      
          <div className="hr"></div>

          <div className="displayMessage">{displayMessage}</div>

          <br></br>
        </>
      )}
    </div>
  );
}

function convertStringToDictionary(data: string, rowDelimiter: string, columnDelimiter: string): { [key: string]: any } {
  const rows = data.split(rowDelimiter);
  let headers = rows[0].split(columnDelimiter);
  if(rows[rows.length -1].length == 0)
    rows.pop()
    if(headers[headers.length -1].length == 0)
     headers.pop()

  const dictionary: { [key: string]: any } = {};

  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split(columnDelimiter);

    for (let j = 0; j < headers.length; j++) {
      const key = headers[j].trim();

      const value = values[j] != undefined ? values[j].trim(): undefined;

      if (!dictionary[key]) {
        dictionary[key] = [];
      }

      dictionary[key].push(value);
    }
  }
  return dictionary;
}

export default TestApp;
