import React, { useState } from 'react';
import TestApp from "./TestApp";
import Banner from "./Banner";
import Login from "./Login";

import SpaAuthService from "../services/SpaAuthService";

import "./App.css";

import IUser from "../models/IUser";

export interface AppState {
  user: IUser;
}

export default class App extends React.Component<any, AppState> {
 
 
  state: AppState = {
    user: {
      IsAuthenticated: false,
      DisplayName: "",
      login: () => {
        this.loginUser();
      },
      logout: () => {
        this.logoutUser();
      },
    },
  };

  loginUser = () => {
    SpaAuthService.login();
  };

  logoutUser = () => {
    SpaAuthService.logout();
    this.setState({
      user: {
        IsAuthenticated: false,
        DisplayName: "",
        login: this.state.user.login,
        logout: this.state.user.logout,
      },
    });
  };

  render() {
    return (
    
      <div id="page-container" className="container">
        <Banner appTitle="React Power BI Embedding">
          <Login user={this.state.user} />
        </Banner>
        {SpaAuthService.accessToken && <TestApp />}
      </div>
    );
  }

  componentDidMount() {
    SpaAuthService.uiUpdateCallback = (userIsAuthenticated: boolean) => {
      if (SpaAuthService.userIsAuthenticated) {
        this.setState({
          user: {
            IsAuthenticated: true,
            DisplayName: SpaAuthService.userDisplayName,
            login: this.state.user.login,
            logout: this.state.user.logout,
          },
        });
      }
    };
    SpaAuthService.init();
  }
}
